<template>
  <div class="body">


    <div class="d-flex flex-row-reverse">
      <v-btn @click="logout" color="brown" style="margin-bottom: 40px;">
        Logout
      </v-btn>
    </div>

    <div class="d-flex justify-center">
      <h1 style="color: aliceblue;">Jamscoffee</h1>
    </div>

    <v-container class="d-flex justify-center mb-6 " flat tile>
      <v-row class="mx-auto justify-center">
        <v-card @click="sales" class="my-12 card" max-width="300">
          <v-img height="200" width="200" src="../assets/sales.png"></v-img>

          <v-card-title class="d-flex justify-center mt-10">
            <p>Penjualan</p>
          </v-card-title>
        </v-card>
        <v-card @click="supply" class="my-12 card" max-width="300">
          <v-img height="200" width="200" src="../assets/supply.png"></v-img>

          <v-card-title class="d-flex justify-center mt-10">
            <p>Supply</p>
          </v-card-title>

        </v-card>

        <v-card @click="profile" class="my-12 card" max-width="300">

          <v-img height="200" width="200" src="../assets/profile.png"></v-img>

          <v-card-title class="d-flex justify-center mt-10">
            <p>Akun</p>
          </v-card-title>

        </v-card>

        <v-card @click="report" class="my-12 card" max-width="300">

          <v-img height="200" width="200" src="../assets/report.png"></v-img>

          <v-card-title class="d-flex justify-center mt-10">
            <p>Report</p>
          </v-card-title>

        </v-card>



      </v-row>

    </v-container>

  </div>
</template>
<script>
import Swal from 'sweetalert2'

export default {
  name: 'home-page',
  data() {
    return {

    }
  },

  created() {
    var userid = localStorage.getItem("userid")
    if (userid != null) {
      this.$router.push("/home");
    }
  },


  methods: {
    logout() {
      Swal.fire({
        title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
        showCancelButton: true,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
        cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.$router.push("/");
        }
      })

    },
    sales() {
      this.$router.push('/transaction');
    },
    supply() {
      this.$router.push('/stok');
    },
    profile() {
      this.$router.push('/profile');
    },
    report() {
      this.$router.push('/report');
    }

  }

}

</script>

<style scoped>
.body {
  background-image: url(../assets/bgmain.jpg);
  background-repeat: repeat-y;
  background-size: auto;
  padding-top: 100px;
  margin-top: -60px;

}

.card {
  cursor: pointer;
  margin: 20px;
  padding: 16px;
  /* background: linear-gradient(180deg, #366660 0%, rgba(35, 48, 46, 0) 100%); */
  /* background: rgba(86, 207, 255, 0.7); */
}

p {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: white;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  /* color: #34758E; */
}
</style>