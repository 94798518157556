<template>
  <div class="body ">

    <div class="d-flex justify-center">
      <h1 style="color: aliceblue;">Jamscoffee</h1>
    </div>

    <v-container class="d-flex justify-center mb-6 " flat tile>
      <v-row class="mx-auto justify-center">
        <v-card @click="login" class="my-12 card" max-width="374">

          <v-img height="250" width="250" src="../assets/supervisor.png"></v-img>

          <v-card-title class="d-flex justify-center mt-10">
            <p>SUPERVISOR</p>
          </v-card-title>

        </v-card>


        <v-card @click="login" class="my-12 card" max-width="374">

          <v-img height="250" width="250" src="../assets/admin.png"></v-img>

          <v-card-title class="d-flex justify-center mt-10">
            <p>ADMIN</p>
          </v-card-title>

        </v-card>

      </v-row>

    </v-container>

  </div>
</template>
<script>

export default {
  name: 'oboard-page',
  data() {
    return {

    }
  },

  created() {
    var userid = localStorage.getItem("userid")
    if (userid != null) {
      this.$router.push("/home");
    }

  },
  methods: {
    login() {
      this.$router.push('/login');
    }
  }

}

</script>

<style scoped>
.body {
  background-image: url(../assets/bgmain.jpg);
  background-size: cover;
}

.card {
  margin: 20px;
  padding: 16px;
  /* background: linear-gradient(180deg, #366660 0%, rgba(35, 48, 46, 0) 100%); */
  background-color: rgb(187, 154, 116);
}

p {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: white;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  /* color: #34758E; */
}
</style>