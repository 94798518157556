<template>
  <div class="body fill-height">
    <v-container class="d-flex  justify-center">
      <v-row>

        <v-col class="mx-auto my-auto" cols="6">
          <div>
            <v-card class="card" max-width="600" max-height="500">

              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="10">
                  <v-card-title class="d-flex justify-center mt-12">
                    <h2>LOGIN</h2>
                  </v-card-title>
                </v-col>
              </v-row>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container class="d-flex justify-center">
                  <v-row>


                    <v-col cols="12" class="my-auto pa-0">
                      <v-text-field v-model="username" label="Username" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="d-flex justify-center">
                  <v-row>

                    <v-text-field @keydown.enter="login" v-model="password" :type="show1 ? 'text' : 'password'"
                      name="input-10-1" label="Password" counter @click:append="show1 = !show1"></v-text-field>


                  </v-row>
                </v-container>

                <v-container class="d-flex justify-center">
                  <v-row>

                    <v-col cols="12" class="mt-6 pa-0 ">
                      <v-btn block color="brown" class="mr-4" @click="login">
                        Login
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>


              <v-dialog v-model="dialoglogin" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                  <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>

            </v-card>
          </div>
        </v-col>
      </v-row>


    </v-container>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import constant from '../utils/constant';

export default {
  name: 'login-page',
  data() {
    return {
      loading: false,
      username: "",
      password: "",
      dialoglogin: false

    }
  },
  created() {
    var userid = localStorage.getItem("userid")
    if (userid != null) {
      this.$router.push("/home");
    }

  },

  methods: {
    login() {

      let a = this
      a.dialoglogin = true

      axios.post(constant.BASE_URL + 'login',
        {
          username: a.username,
          password: a.password
        })
        .then(function (response) {

          console.log(response);

          var item = response.data.data[0];

          localStorage.setItem("userid", item.id);
          localStorage.setItem("username", item.username);
          localStorage.setItem("userRole", item.role);

          a.dialoglogin = false

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Login Berhasil</h3>",
            showConfirmButton: false,
          })

          a.$router.push('/home');
        }).catch(function (error) {

          a.dialoglogin = false

          Swal.fire({
            icon: 'error',
            title: "<h5 style='font-family:ros;'>Email atau Password Salah</h5>"
          })
          console.log(error);
        });
    }

  }

}

</script>

<style>
.body {
  background-image: url(../assets/bgmain.jpg);
  background-repeat: repeat;
  background-size: cover;

}

.card {
  margin: 20px;
  padding: 40px;
  background-color: rgb(187, 154, 116) !important;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */

}



h2 {

  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: black;

}

.fontbaru {
  font-family: 'Courier New', Courier, monospace;
}

p {

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}
</style>