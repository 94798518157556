<template>
  <div class="body fill-height">


    <div class="d-flex flex-row-reverse">
      <v-btn @click="logout" color="brown" style="margin-bottom: 40px;">
        Logout
      </v-btn>
    </div>

    <div class="d-flex flex-row-reverse">

      <v-btn @click="profile" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-account
        </v-icon>
      </v-btn>

      <v-btn @click="report" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-timeline
        </v-icon>
      </v-btn>

      <v-btn @click="supply" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-widgets
        </v-icon>
      </v-btn>

      <v-btn @click="sales" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-format-list-bulleted-square
        </v-icon>
      </v-btn>

      <v-btn @click="home" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-home
        </v-icon>
      </v-btn>

    </div>


    <v-data-table style="background-color: rgb(187,154,116); margin-top: 40px;" :headers="headers" :items="desserts"
      sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat style="background-color: rgb(187,154,116);">
          <v-toolbar-title>Supply</v-toolbar-title>

          <v-row style="background-color: rgb(187,154,116);; margin-top: 40px; margin-bottom: 26px; margin-left: 30px;">
            <v-col cols="3" sm="3" md="3">
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="startDate"
                transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="startDate" label="Start Date" prepend-icon="mdi-calendar" readonly v-on="on"
                    v-bind="attrs"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title scrollable @input="$refs.menu1.save(startDate)">
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="3" sm="3" md="3">
              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="endDate"
                transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="endDate" label="End Date" prepend-icon="mdi-calendar" readonly v-on="on"
                    v-bind="attrs"></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(endDate)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn @click="filterData" color="brown">
                Filter
              </v-btn>
            </v-col>


          </v-row>

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="brown" dark class="mb-2" v-bind="attrs" v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete v-model="editedItem.barang" label="Nama Barang" :items="listProductName"
                        hide-details single-line></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete v-model="editedItem.satuan" label="satuan" :items="listSatuan" hide-details
                        single-line></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="date" v-model="editedItem.tanggal" label="Tanggal"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.qty" label="Quantity"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.harga" label="Harga"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.subtotal" label="Subtotal"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:[`item.harga`]="{ item }">

        <p style="font-size: 13px;">{{ formatRupiah(item.harga) }}</p>

      </template>

      <template v-slot:[`item.subtotal`]="{ item }">

        <p style="font-size: 13px;">{{ formatRupiah(item.subtotal) }}</p>

      </template>


      <template v-slot:no-data>
        <v-btn color="primary" @click="getData">
          Reset
        </v-btn>
      </template>
    </v-data-table>

    <div class="d-flex">
      <download-excel :data="desserts">
        <v-btn color="brown" style="margin-top: 40px;">
          Export Excel
        </v-btn>
      </download-excel>
    </div>

  </div>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
import constant from '../utils/constant';


export default {
  name: 'stok-page',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Tanggal', value: 'tanggal' },
      {
        text: 'Barang',
        align: 'start',
        value: 'barang',
      },
      {
        text: 'Satuan',
        align: 'start',
        value: 'satuan',
      },
      { text: 'Qty', value: 'qty' },
      { text: 'Harga', value: 'harga' },
      { text: 'Subtotal', value: 'subtotal' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    deleteId: 0,
    listSatuan: ["pack", "pcs", "botol", "bungkus", "sachet", "kaleng", "galon", "kotak"],
    listProductName: ["Beans House Blend Kdh",
      "Beef Potato",
      "Croissant Almond",
      "Croissant Cheese",
      "Croissant Chocolate",
      "Croissant Double Choco",
      "Oat Biscuit",
      "Tuna Puff",
      "Backflush Blind Porta Filter",
      "Botol",
      "Dripp Pandan Syrup",
      "Es Batu",
      "Sauce Chili Delmonte",
      "Sticker Botol Cold Brew",
      "Sticker Botol Cold Brew Aceh Gayo",
      "Sticker Botol Cold Brew Colombia",
      "Sticker Botol Cold Brew Kintamani",
      "Sticker Botol Cold Brew Toraja",
      "Sticker Bulat Paperbag Roti",
      "Powder Creamer",
      "Powder Queen Chocolate",
      "Powder Queen Machiato",
      "Powder Queen Matcha",
      "Powder Queen Redvelvet",
      "Black Tea",
      "Boba Pearl",
      "Gula Aren Cair",
      "Gula Aren Stick",
      "Gula Pasir",
      "Gula Pasir Stick",
      "Madu 650Ml",
      "Nutrisari Lychee",
      "Nutrisari Orange/Jeruk",
      "Nutrisari Strawberry",
      "Puro Espreso Machine",
      "Cup Cold 12Oz Polos",
      "Cup Hot 8Oz Hitam Print",
      "New Cup Cold 12Oz Print",
      "New Cup Hot 8Oz Putih Prnit",
      "Paper Cup Polos",
      "Paper Roti Polos",
      "Paper Roti Print",
      "Plastik Take Away Double",
      "Plastik Wrapping",
      "Sleeve Jacket Cup Hot",
      "Straw Boba",
      "Straw Hot",
      "Straw Ice",
      "Tutup Paper Cup",
      "Susu Fresh Milk Diamond",
      "Susu Kental Manis",
      "Syrup Monin Almond",
      "Syrup Monin Caramel",
      "Syrup Monin Hazelnut",
      "Syrup Monin Salted Caramel",
      "Syrup Monin Vanilla",
      "Syrup Monin pandan",
      "Air Galon",
      "Earl Grey",
      "chamomile",
      "English breakfast",
      "paper mint",
      "Powder Queen Taro",
      "Poping boba lychee",
      "Poping boba Strawberry"],
    editedItem: {},
    startDate: "",
    endDate: "",
    menu1: false,
    menu2: false,
    defaultItem: {
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {

    var userid = localStorage.getItem("userid")
    if (userid == null) {
      this.$router.push("/");
    }

    this.getData()
  },

  methods: {
    getData() {

      let a = this

      axios.get(constant.BASE_URL + "supply")
        .then(function (response) {

          let newdata = response.data.data;
          a.desserts = newdata

        }).catch(function (error) {
          console.log(error);
        })

    },
    filterData() {

      const filteredData = this.desserts.filter(item => {
        const itemDate = new Date(item.tanggal);
        return itemDate >= new Date(this.startDate) && itemDate <= new Date(this.endDate);
      });

      this.desserts = filteredData

    },
    logout() {
      Swal.fire({
        title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
        showCancelButton: true,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
        cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.$router.push("/");
        }
      })

    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = item
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = item
      this.deleteId = item.id
      this.dialogDelete = true
    },

    deleteItemConfirm() {

      let a = this

      axios.delete(constant.BASE_URL + "supply/" + this.deleteId)
        .then(function () {
          a.dialogDelete = false

          Swal.fire({
            icon: 'success',
            title: 'Data berhasil dihapus',
            showConfirmButton: false
          })

          a.getData()

        }).catch(function (error) {
          a.dialogDelete = false
          console.log(error);

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error
          })
        })


      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {}
        this.editedIndex = -1
      })
    },
    formatRupiah(number) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR'
      });

      return formatter.format(number);
    },
    home() {
      this.$router.push('/home');
    },
    sales() {
      this.$router.push('/transaction');
    },
    supply() {
      this.$router.push('/stok');
    },
    profile() {
      this.$router.push('/profile');
    },
    report() {
      this.$router.push('/report');
    },

    save() {

      let a = this

      if (a.editedIndex > -1) {
        //edit
        console.log("eidteditem =" + a.editedItem)
        axios.put(constant.BASE_URL + "supply", a.editedItem).then(function (response) {

          console.log(response);

          Swal.fire({
            icon: 'success',
            title: 'berhasil',
            showConfirmButton: false,
            timer: 1500
          })

          a.getData()


        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error
          })
          console.log(error);
        })
      } else {
        //insert
        axios.post(constant.BASE_URL + "ticket", a.editedItem).then(function (response) {

          console.log(response);

          Swal.fire({
            icon: 'success',
            title: 'berhasil',
            showConfirmButton: false,
            timer: 1500
          })

          a.getData()


        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error
          })
          console.log(error);
        })
      }
      this.close()
    },
  },


}

</script>

<style scoped>
.body {
  background-image: url(../assets/bgmain.jpg);
  background-size: auto;
  padding: 100px;
  margin-top: -60px;
}
</style>