<template>
  <div class="body ">

    <div class="d-flex flex-row-reverse">
      <v-btn @click="logout" color="brown" style="margin-bottom: 40px;">
        Logout
      </v-btn>
    </div>

    <div class="d-flex flex-row-reverse">

      <v-btn @click="profile" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-account
        </v-icon>
      </v-btn>

      <v-btn @click="report" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-timeline
        </v-icon>
      </v-btn>

      <v-btn @click="supply" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-widgets
        </v-icon>
      </v-btn>

      <v-btn @click="sales" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-format-list-bulleted-square
        </v-icon>
      </v-btn>

      <v-btn @click="home" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-home
        </v-icon>
      </v-btn>

    </div>

    <div style="margin-top: 40px; background-color: rgb(187,154,116); padding: 40px; opacity: 0.9;">
      <div style="text-align: start; padding: 40px;">

        <v-row style="background-color: rgb(187,154,116); margin-bottom: 20px;">
          <v-col cols="3" sm="3" md="3">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="startDate"
              transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="startDate" label="Start Date" prepend-icon="mdi-calendar" readonly v-on="on"
                  v-bind="attrs"></v-text-field>
              </template>
              <v-date-picker v-model="startDate" no-title scrollable @input="$refs.menu1.save(startDate)">
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="3" sm="3" md="3">
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="endDate"
              transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="endDate" label="End Date" prepend-icon="mdi-calendar" readonly v-on="on"
                  v-bind="attrs"></v-text-field>
              </template>
              <v-date-picker v-model="endDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(endDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <v-btn @click="filterData" color="brown">
              Filter
            </v-btn>
          </v-col>

        </v-row>

        <p>Penjualan : {{ formatRupiah(totalPenjualan) }}</p>

        <p>Hpp: {{ formatRupiah(totalSupply) }}</p>

        <p>Laba Kotor: {{ formatRupiah(totalKeuntungan) }}</p>

        <p>Gross Profit Margin: {{ totalMargin }} %</p>

      </div>

      <apexcharts ref="realtimeChart" height="350" type="line" :options="options" :series="series"></apexcharts>

    </div>

  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import Swal from 'sweetalert2'
import axios from 'axios';
import constant from '../utils/constant';

export default {
  name: 'report-page',
  components: {
    apexcharts: VueApexCharts
  },
  data() {
    return {
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: ["jan", "feb", "mar", "apr", "mei", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
        }
      },
      series: [{
        name: 'Penjualan',
        data: []
      },
      {
        name: 'Hpp',
        data: []
      },
      {
        name: 'Profit',
        data: []
      }
      ],
      listTransaction: [],
      listSupply: [],
      totalPenjualan: 0,
      totalSupply: 0,
      totalKeuntungan: 0,
      totalMargin: 0,
      startDate: "",
      endDate: "",
      menu1: false,
      menu2: false,
      cartTransaction: [],
      cartSupply: [],
      cartProfit: []
    }
  },
  created() {
    var userid = localStorage.getItem("userid")
    if (userid == null) {
      this.$router.push("/");
    }

    this.getDataTransaction()

  },
  methods: {
    setPerhitungan() {
      this.totalKeuntungan = parseInt(this.totalPenjualan) - parseInt(this.totalSupply)
      this.totalMargin = ((parseInt(this.totalKeuntungan) / parseInt(this.totalPenjualan)) * 100).toFixed(2)
      this.setCart()
    },
    filterData() {

      const filteredData = this.listTransaction.filter(item => {
        const itemDate = new Date(this.transformDate(item.tanggal));
        return itemDate >= new Date(this.startDate) && itemDate <= new Date(this.endDate);
      });

      this.listTransaction = filteredData

      const filteredData2 = this.listSupply.filter(item => {
        const itemDate = new Date(item.tanggal);
        return itemDate >= new Date(this.startDate) && itemDate <= new Date(this.endDate);
      });

      this.listSupply = filteredData2

      this.setPerhitungan()

    },

    setCart() {
      this.cartTransaction = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.cartSupply = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.cartProfit = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

      for (let i = 0; i < this.listTransaction.length; i++) {
        var item = this.listTransaction[i]
        var date = new Date(this.transformDate(item.tanggal));
        var monthIndex = date.getMonth();
        this.cartTransaction[monthIndex] += parseInt(item.subtotal);
      }

      for (let i = 0; i < this.listSupply.length; i++) {
        var itemsupply = this.listSupply[i]
        var datesupply = new Date(itemsupply.tanggal);
        var monthIndexsuplly = datesupply.getMonth();
        this.cartSupply[monthIndexsuplly] += parseInt(itemsupply.subtotal);
      }

      for (let i = 0; i < 12; i++) {
        this.cartProfit[i] = parseInt(this.cartTransaction[i]) - parseInt(this.cartSupply[i])
      }

      this.updateSeriesLine()

    },

    transformDate(inputDate) {
      const [datePart] = inputDate.split(' ');
      const [day, month, year] = datePart.split('/');
      const transformedDate = new Date(`${year}-${month}-${day}`);
      const formattedDate = transformedDate.toISOString().split('T')[0];

      return formattedDate;
    },
    formatRupiah(number) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR'
      });

      return formatter.format(number);
    },
    getDataTransaction() {
      let a = this

      axios.get(constant.BASE_URL + "transaction")
        .then(function (response) {

          let newdata = response.data.data;


          for (let i = 0; i < newdata.length; i++) {

            a.totalPenjualan += parseInt(newdata[i].subtotal)

          }

          console.log(newdata)

          a.listTransaction = newdata

          a.getDataSupply()

        }).catch(function (error) {
          console.log(error);
        })

    },

    getDataSupply() {

      let a = this

      axios.get(constant.BASE_URL + "supply")
        .then(function (response) {

          let newdata = response.data.data;

          for (let i = 0; i < newdata.length; i++) {

            a.totalSupply += parseInt(newdata[i].subtotal)

          }

          console.log(newdata)

          a.listSupply = newdata
          a.setPerhitungan()

        }).catch(function (error) {
          console.log(error);
        })

    },

    convertToMonth(dateString) {
      const date = new Date(dateString);
      const month = date.toLocaleString('default', { month: 'short' });
      return month;
    },

    isCurrentYear(dateString) {
      const currentDate = new Date(); // Get the current date
      const currentYear = currentDate.getFullYear(); // Get the current year

      const providedDate = new Date(dateString); // Convert the provided dateString to a Date object
      const providedYear = providedDate.getFullYear(); // Get the year from the provided date

      return currentYear === providedYear;
    },

    updateSeriesLine() {

      this.series[0].data = this.cartTransaction
      this.series[1].data = this.cartSupply
      this.series[2].data = this.cartProfit


      this.$refs.realtimeChart.updateSeries([
        {
          data: this.series[0].data,
        },
        {
          data: this.series[1].data,
        },
        {
          data: this.series[2].data,
        }
      ], false, true);
    },
    logout() {
      Swal.fire({
        title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
        showCancelButton: true,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
        cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.$router.push("/");
        }
      })

    },
    home() {
      this.$router.push('/home');
    },
    sales() {
      this.$router.push('/transaction');
    },
    supply() {
      this.$router.push('/stok');
    },
    profile() {
      this.$router.push('/profile');
    },
    report() {
      this.$router.push('/report');
    }

  }


}

</script>

<style scoped>
.body {
  background-image: url(../assets/bgmain.jpg);
  background-size: auto;
  padding: 100px;
  margin-top: -60px;
}
</style>