<template>
  <div class="body fill-height">

    <div class="d-flex flex-row-reverse">
      <v-btn @click="logout" color="brown" style="margin-bottom: 40px;">
        Logout
      </v-btn>
    </div>

    <div class="d-flex flex-row-reverse">

      <v-btn @click="profile" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-account
        </v-icon>
      </v-btn>

      <v-btn @click="report" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-timeline
        </v-icon>
      </v-btn>

      <v-btn @click="supply" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-widgets
        </v-icon>
      </v-btn>

      <v-btn @click="sales" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-format-list-bulleted-square
        </v-icon>
      </v-btn>

      <v-btn @click="home" class="mx-2" fab dark color="brown">
        <v-icon dark>
          mdi-home
        </v-icon>
      </v-btn>

    </div>

    <v-data-table style="background-color: rgb(187,154,116); margin-top: 40px;" :headers="headers" :items="desserts"
      sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat style="background-color: rgb(187,154,116) ;">


          <v-toolbar-title>Penjualan</v-toolbar-title>

          <v-row style="background-color: rgb(187,154,116); margin-top: 40px; margin-bottom: 26px; margin-left: 30px;">
            <v-col cols="3" sm="3" md="3">
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="startDate"
                transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="startDate" label="Start Date" prepend-icon="mdi-calendar" readonly v-on="on"
                    v-bind="attrs"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title scrollable @input="$refs.menu1.save(startDate)">
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="3" sm="3" md="3">
              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="endDate"
                transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="endDate" label="End Date" prepend-icon="mdi-calendar" readonly v-on="on"
                    v-bind="attrs"></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(endDate)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn @click="filterData" color="brown">
                Filter
              </v-btn>
            </v-col>


          </v-row>

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">

            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.gerai" label="Gerai"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.channel" label="Channel"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="date" v-model="editedItem.tanggal" label="Tanggal"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.nomerstruk" label="Nomer Struk"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.staff" label="Staff"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.kasir" label="Kasir"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.pelanggan" label="Pelanggan"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.meja" label="Meja"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.status" label="Status"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.metode" label="Metode"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.catatan" label="Catatan"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.kasbon" label="Kasbon"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.jatuhtempo" label="Jatuh Tempo"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.referensi" label="Referensi"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.biayalayanan" label="Biaya Layanan"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.pajak" label="Pajak"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.pembulatan" label="Pembulatan"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.namabarang" label="Nama Barang"></v-text-field>
                    </v-col>


                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.qty" label="Qty"></v-text-field>
                    </v-col>



                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.subtotal" label="Subtotal"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.diskon" label="Diskon"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.total" label="Total"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.pembayaran" label="Pembayaran"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model="editedItem.kembalian" label="Kembalian"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:[`item.subtotal`]="{ item }">

        <p style="font-size: 13px;">{{ formatRupiah(item.subtotal) }}</p>

      </template>

      <template v-slot:[`item.total`]="{ item }">

        <p style="font-size: 13px;">{{ formatRupiah(item.total) }}</p>

      </template>

      <template v-slot:[`item.pembayaran`]="{ item }">

        <p style="font-size: 13px;">{{ formatRupiah(item.pembayaran) }}</p>

      </template>

      <template v-slot:[`item.kembalian`]="{ item }">

        <p style="font-size: 13px;">{{ formatRupiah(item.kembalian) }}</p>

      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getData">
          Reset
        </v-btn>
      </template>
    </v-data-table>


    <div v-if="isAdmin" class="d-flex">
      <div style="margin-top:30px;">

        <input style="color: brown;" type="file" @change="onChange" />
        <xlsx-read :file="file">
          <xlsx-json style="display:none">
            <template #default="{ collection }">
              <div>
                {{ saveToJson(collection) }}
              </div>
            </template>
          </xlsx-json>
        </xlsx-read>
      </div>


    </div>
    <div class="d-flex">
      <v-btn @click="uploadJson" color="brown" style="margin-top: 12px; color:white;">
        Upload Excel
      </v-btn>

      <v-dialog v-model="dialogUpload" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>

  </div>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
import constant from '../utils/constant';
import { XlsxRead, XlsxJson } from "vue-xlsx"

export default {
  name: 'transaction-page',
  components: {
    XlsxRead,
    XlsxJson
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogUpload: false,
    isImport: 0,
    headers: [
      {
        text: 'Gerai',
        align: 'start',
        sortable: false,
        value: 'gerai',
        width: '150'
      },
      { text: 'Tanggal', value: 'tanggal', width: '150' },
      { text: 'Nomer Struk', value: 'nomerstruk', width: '150' },
      { text: 'Staff', value: 'staff', width: '150' },
      { text: 'Kasir', value: 'kasir', width: '150' },
      // { text: 'Pelanggan', value: 'pelanggan', width: '150' },
      // { text: 'Meja', value: 'meja', width: '150' },
      { text: 'Status', value: 'status', width: '150' },
      { text: 'Nama barang', value: 'namabarang', width: '150' },
      { text: 'Catatan', value: 'catatan', width: '150' },
      { text: 'Qty', value: 'qty', width: '150' },
      { text: 'Harga', value: 'subtotal', width: '150' },
      { text: 'Diskon', value: 'diskon', width: '150' },
      { text: 'Total', value: 'total', width: '150' },

      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    deleteId: 0,
    startDate: "",
    endDate: "",
    menu1: false,
    isAdmin: true,
    inputData: [],
    uploadData: [],
    menu2: false,
    editedItem: {
    },
    defaultItem: {
    },
    file: null,
    collection: ""
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  created() {
    var userid = localStorage.getItem("userid")
    var userRole = localStorage.getItem("userRole")

    if (userid == null) {
      this.$router.push("/");
    }

    if (userRole == "supervisor") {
      this.isAdmin = false
    }

    this.getData()
  },
  methods: {
    getData() {

      let a = this

      axios.get(constant.BASE_URL + "transaction")
        .then(function (response) {

          let newdata = response.data.data;

          console.log(newdata)

          a.desserts = newdata

        }).catch(function (error) {
          console.log(error);
        })

    },
    formatRupiah(number) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR'
      });

      return formatter.format(number);
    },
    transformDate(inputDate) {
      const [datePart] = inputDate.split(' ');
      const [day, month, year] = datePart.split('/');
      const transformedDate = new Date(`${year}-${month}-${day}`);
      const formattedDate = transformedDate.toISOString().split('T')[0];

      return formattedDate;
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;

    },
    uploadJson() {

      if (this.file == null) {

        Swal.fire({
          icon: 'error',
          title: 'Please Select File'
        })

      } else {
        this.dialogUpload = true
        for (let i = 3; i < this.inputData.length - 1; i++) {
          var itemdata = this.inputData[i]

          var itemUpload = {
            "gerai": itemdata.__EMPTY,
            "tanggal": itemdata.__EMPTY_1,
            "nomerstruk": itemdata.__EMPTY_2,
            "staff": itemdata.__EMPTY_3,
            "kasir": itemdata.__EMPTY_4,
            "pelanggan": itemdata.__EMPTY_5,
            "meja": itemdata.__EMPTY_6,
            "status": itemdata.__EMPTY_7,
            "namabarang": itemdata.__EMPTY_8,
            "catatan": itemdata.__EMPTY_10,
            "qty": itemdata.__EMPTY_12,
            "subtotal": itemdata.__EMPTY_11,
            "diskon": itemdata.__EMPTY_13,
            "total": itemdata.__EMPTY_24
          }
          this.insertData(
            itemUpload
          )


        }

        Swal.fire({
          icon: 'success',
          title: 'import complete',
          showConfirmButton: false,
          timer: 1500
        })
        this.dialogUpload = false
        this.getData()

      }

    },
    saveToJson(item) {
      this.inputData = item
    },

    logout() {
      Swal.fire({
        title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
        showCancelButton: true,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
        cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.$router.push("/");
        }
      })

    },


    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = item
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = item
      this.deleteId = item.id
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      let a = this

      axios.delete(constant.BASE_URL + "transaction/" + this.deleteId)
        .then(function (response) {

          let newdata = response.data.data;

          console.log(newdata)
          a.dialogDelete = false

          Swal.fire({
            icon: 'success',
            title: 'Data berhasil dihapus',
            showConfirmButton: false
          })

          a.getData()

        }).catch(function (error) {
          a.dialogDelete = false
          console.log(error);

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error
          })
        })
      this.closeDelete()
    },

    updateData() {

      let a = this

      axios.put(constant.BASE_URL + "transaction", a.editedItem).then(function (response) {

        console.log(response);

        Swal.fire({
          icon: 'success',
          title: 'berhasil',
          showConfirmButton: false,
          timer: 1500
        })

        a.getData()


      }).catch(function (error) {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error
        })
        console.log(error);
      })
    },
    filterData() {

      const filteredData = this.desserts.filter(item => {
        const itemDate = new Date(this.transformDate(item.tanggal));
        return itemDate >= new Date(this.startDate) && itemDate <= new Date(this.endDate);
      });

      this.desserts = filteredData

    },
    insertData(item) {
      axios.post(constant.BASE_URL + "transaction", item).then(function (response) {
        console.log(response)
      }).catch(function (error) {
        console.log(error);
      })

    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {}
        this.editedIndex = -1
      })
    },
    home() {
      this.$router.push('/home');
    },
    sales() {
      this.$router.push('/transaction');
    },
    supply() {
      this.$router.push('/stok');
    },
    profile() {
      this.$router.push('/profile');
    },
    report() {
      this.$router.push('/report');
    },

    save() {
      if (this.editedIndex > -1) {

        this.updateData()

      } else {
        this.insertData(this.editedItem)
      }
      this.close()
    },
  },

}

</script>

<style scoped>
.body {
  background-image: url(../assets/bgmain.jpg);
  background-size: auto;
  padding: 100px;
  margin-top: -60px;


}
</style>