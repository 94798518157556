import Vue from 'vue'
import Router from 'vue-router'

import Home from '../views/home.vue'
import Login from '../views/login.vue'
import Onboard from '../views/onboard.vue'
import Report from '../views/report.vue'
import Stok from '../views/stok.vue'
import Transaction from '../views/transaction.vue'
import Profile from '../views/profile.vue'




Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path : '/',
            name:'Onboard',
            component: Onboard,
            props:true
        },
        {
            path : '/home',
            name:'Home',
            component: Home
        },
        {
            path : '/login',
            name:'Login',
            component: Login
        },
        {
            path : '/stok',
            name:'Stok',
            component: Stok
        },
        {
            path : '/transaction',
            name:'Transaction',
            component: Transaction
        },
        {
            path : '/report',
            name:'Report',
            component: Report
        },
        {
            path : '/profile',
            name:'Profile',
            component: Profile
        }  
    
    ]
})
